import React from "react";
import Layout from "../components/layout";
import {
  Avatar,
  Container,
  Chip,
  Typography,
  Link,
  ListItem,
  ListItemText,
  List
} from "@material-ui/core";
import styled from "styled-components";
import { SectionHeading } from "../components/atoms";
import clientsData from "../content/clients";
import IndustryIcon from "@material-ui/icons/Business";
import BusinessIcon from "@material-ui/icons/Storefront";
import SchoolIcon from "@material-ui/icons/School";
import HouseIcon from "@material-ui/icons/House";
import Hospital from "@material-ui/icons/LocalHospital";
import Contractors from "@material-ui/icons/SupervisedUserCircle";
import Homework from "@material-ui/icons/HomeWork";

const icons = [
  <IndustryIcon />,
  <BusinessIcon />,
  <HouseIcon />,
  <Contractors />,
  <Homework />,
  <Hospital />,
  <SchoolIcon />
];

const StyledContainer = styled(Container)`
  margin-bottom: 64px;
  margin-top: 120px;
`;

const StyledHeading = styled.div`
  display: flex;
  justify-content: center;
`;

const StyledChipContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin-bottom: 64px;
`;

const StyledChip = styled(Chip)`
  margin: 8px 8px;
  cursor: pointer;
  &:hover {
    background-color: #ffc30e;
    color: black;
    border-color: transparent;
  }
`;

const StyledList = styled(List)`
  display: flex;
  flex-wrap: wrap;
  margin: 16px 0 48px 0;
  & li {
    padding-left: 0;
    flex-basis: 25%;
    @media (max-width: 991px) {
      flex-basis: 33%;
    }
    @media (max-width: 768px) {
      flex-basis: 50%;
    }
  }
  @media (max-width: 479px) {
    flex-direction: column;
    align-items: center;
  }
`;

const StyledTitle = styled.div`
  display: flex;
  align-items: center;
  & > div {
    margin-right: 16px;
    background-color: #ffc30e;
  }
`;

const ClientList = ({ clientList, category, iconIndex }) => {
  return (
    <>
      <StyledTitle id={category}>
        <Avatar>{icons[iconIndex]}</Avatar>
        <Typography variant="h6" as="h2">
          {category}
        </Typography>
      </StyledTitle>
      <StyledList>
        {clientList["names"].map(client => (
          <ListItem key={client}>
            <ListItemText primary={client} secondary={null} />
          </ListItem>
        ))}
      </StyledList>
    </>
  );
};

const Clients = () => {
  const [categories, setCategories] = React.useState([
    "Industrial",
    "Business",
    "PropertyMgmt",
    "Contractors",
    "Religious",
    "Medical",
    "Education"
  ]);
  return (
    <Layout>
      <StyledContainer maxWidth="xl">
        <StyledHeading>
          <SectionHeading
            textAlign="center"
            tagline="HAPPY CLIENTS, HAPPY GEI"
            heading="Clients"
            subheading="We serve hundreds of clients. Our clients include Industrial, business, educational, Religious and more. Let us serve you next."
          />
        </StyledHeading>
        <StyledChipContainer>
          {categories.map((cat, index) => (
            <Link href={`#${cat}`} key={cat} style={{ textDecoration: "none" }}>
              <StyledChip variant="outlined" label={cat} icon={icons[index]} />
            </Link>
          ))}
        </StyledChipContainer>
        {[0, 1, 2, 3, 4, 5, 6].map(x => (
          <ClientList
            iconIndex={x}
            clientList={clientsData.clients[x]}
            category={categories[x]}
          />
        ))}
      </StyledContainer>
    </Layout>
  );
};

export default Clients;
